import { useEffect, useState } from "react";

import pdfToText from "react-pdftotext"; // PDF text extraction
import Tesseract from "tesseract.js"; // OCR (image-to-text)
import { sendOCR } from "./backendCalls";

import { getAuthInfoFromStorage } from "../utils/localStorageUtils";

export const AssignmentsHandler = (selectedAssignment, setAssignmentText, devFlag) => {
    const [assignments, setAssignments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fileProgress, setFileProgress] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState(new Set());

    // update assignment text based on the currently selected assignment
    useEffect(() => {
        if (selectedAssignment) {
            const currentAssignment = assignments.find(
                (assignment) => assignment.title === selectedAssignment.title
            );
            if (currentAssignment) {
                setAssignmentText(currentAssignment.text);
            }
        }
    }, [assignments, selectedAssignment, setAssignmentText]);

    // function to upload a file
    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files);
        if (files.length === 0) return;

        setLoading(true);

        const progressPromises = files.map(async (file) => {
            // check if the file has already been uploaded
            if (uploadedFiles.has(file.name)) {
                console.log(`File "${file.name}" has already been uploaded.`);
                setLoading(false); // stop loading before returning
                return; // skip processing this file
            }

            const newAssignment = {
                title: file.name,
                fileType: file.type,
                date: new Date(),
                text: "", // start with empty text
                file: file,
            };

            // immediately add the assignment to the state
            setAssignments((prev) => [...prev, newAssignment]);

            // initialize progress to 0 for this specific file
            setFileProgress((prev) => ({
                ...prev,
                [file.name]: 0,
            }));

            let text = "";

            text = await extractTextBackend(file, newAssignment.title);

            // update the assignment with extracted text after processing
            setAssignments((prev) =>
                prev.map((assignment) =>
                    assignment.title === newAssignment.title
                        ? { ...assignment, text }
                        : assignment
                )
            );

            // update the set of uploaded files using setUploadedFiles
            setUploadedFiles((prev) => new Set(prev).add(file.name));
        });

        await Promise.all(progressPromises);
        setLoading(false);
        e.target.value = null; // reset file input
    };

    const handleOCRResponse = (response) => {
        localStorage.setItem("questions", JSON.stringify(response['questions']))
        return response["text"]
    }


    const extractTextBackend = async (file, title) => {
        const totalSteps = 100; // total steps for progress simulation
        let currentStep = 0;
    
        return new Promise(async (resolve) => {
            // Start the OCR process (but don't block)
            const authInfo = getAuthInfoFromStorage()
            const textPromise = sendOCR(file, devFlag=authInfo['user']['email'] === "alex@collabria.co");
    
            // Start the progress bar update interval
            const interval = setInterval(() => {
                if (currentStep < totalSteps) {
                    currentStep += 10; // increase progress
                    setFileProgress((prev) => ({
                        ...prev,
                        [title]: currentStep, // update specific file progress
                    }));
                } else {
                    clearInterval(interval);
                    setFileProgress((prev) => ({
                        ...prev,
                        [title]: 100, // ensure it ends at 100%
                    }));
                }
            }, 100); // update progress every 100 ms
    
            // Wait for OCR to finish
            const text = await textPromise;
    
            // Ensure interval is cleared after the OCR process ends, even if progress hasn't reached 100%
            clearInterval(interval);
            setFileProgress((prev) => ({
                ...prev,
                [title]: 100, // ensure the progress ends at 100%
            }));
    
            // Resolve the promise with the OCR result
            resolve(handleOCRResponse(text));
        });
    };
    

    // function to extract text from PDF with simulated progress
    const extractTextFromPDF = async (pdfFile, title) => {
        const totalSteps = 100; // total steps for progress simulation
        let currentStep = 0;

        return new Promise(async (resolve) => {
            const text = await pdfToText(pdfFile);
            const interval = setInterval(() => {
                if (currentStep < totalSteps) {
                    currentStep += 10; // increase progress
                    setFileProgress((prev) => ({
                        ...prev,
                        [title]: currentStep, // update specific file progress
                    }));
                } else {
                    clearInterval(interval);
                    setFileProgress((prev) => ({
                        ...prev,
                        [title]: 100, // ensure it ends at 100%
                    }));
                    resolve(formatPDFText(text));
                }
            }, 100); // update progress every 100 ms
        });
    };

    // function to extract text from image
    const extractTextFromImage = async (imageFile, title) => {
        return new Promise((resolve, reject) => {
            Tesseract.recognize(imageFile, "eng", {
                logger: (info) => {
                    if (info.status === "recognizing text") {
                        setFileProgress((prev) => ({
                            ...prev,
                            [title]: Math.round(info.progress * 100), // update specific file progress
                        }));
                    }
                },
            })
                .then(({ data: { text } }) => {
                    setFileProgress((prev) => ({
                        ...prev,
                        [title]: 100, // ensure it ends at 100% when done
                    }));
                    resolve(text);
                })
                .catch((error) => {
                    console.error("Failed to extract text from image:", error);
                    reject(error);
                });
        });
    };

    const formatPDFText = (text) => {
        return text
            .split("\n")
            .map((line) => line.trim())
            .join("\n");
    };

    // function to update the assignment title
    const updateAssignmentTitle = (index, newTitle, currentProgress) => {
        setAssignments((prevAssignments) => {
            const updatedAssignments = [...prevAssignments];

            updatedAssignments[index] = {
                ...updatedAssignments[index],
                title: newTitle, // Update title
            };

            // Preserve progress for the new title
            setFileProgress((prevProgress) => ({
                ...prevProgress,
                [newTitle]: currentProgress, // Keep progress for the new title
                [prevAssignments[index].title]: undefined, // Remove old title's progress
            }));

            return updatedAssignments;
        });
    };

    return {
        assignments,
        fileProgress,
        updateAssignmentTitle,
        handleFileUpload,
    };
};
