import React, { useState } from "react";
import { PopUpHandler } from "../../utils/PopUpHandler";

import "./popups.css";

const POPUP_TYPES = {
    REWARDS: "rewards",
    AVATAR: "avatar",
    CAMERA: "camera",
    LOGIN: "login",
    EDITAVATAR: "editAvatar",
};

export const PopUps = ({
    setIsLogin,

    setShowPopup,

    currentPopup,
    setCurrentPopup,

    handleReset,

    avatarState,
    setAvatarState,

    sendMessage,
    generateResponse,

    handleFileUpload,

    signInHandler,
    createAccountHandler,
    setKeepLogin,
}) => {
    const [loginTitle, setLoginTitle] = useState("Login");

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleRewardsPopUp = () => {
        setCurrentPopup(POPUP_TYPES.REWARDS);
    };

    const handleAvatarPopUp = () => {
        setCurrentPopup(POPUP_TYPES.AVATAR);
    };

    const handleCameraPopUp = () => {
        setCurrentPopup(POPUP_TYPES.CAMERA);
    };

    const handleLoginPopUp = () => {
        setCurrentPopup(POPUP_TYPES.LOGIN);
    };

    const handleEditAvatarPopUp = () => {
        setCurrentPopup(POPUP_TYPES.EDITAVATAR);
    };

    const popupContent = PopUpHandler(
        handleClosePopup,
        handleRewardsPopUp,
        handleAvatarPopUp,
        handleCameraPopUp,
        handleLoginPopUp,
        handleEditAvatarPopUp,
        handleReset,

        avatarState,
        setAvatarState,

        sendMessage,
        generateResponse,
        handleFileUpload,

        setIsLogin,
        loginTitle,
        setLoginTitle,

        signInHandler,
        createAccountHandler,
        setKeepLogin
    );

    return (
        <div className="popup-overlay">
            <div className="popup">
                <h1>{popupContent[currentPopup].title}</h1>
                {popupContent[currentPopup].content}
            </div>
        </div>
    );
};
