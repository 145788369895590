import React from "react";

import "./loader.css";

export const Loader = ({}) => {
    return (
        <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    );
};
