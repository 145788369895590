import { useEffect, useState } from "react";
import { createAccount, signIn, refreshAuthentication } from "./authCalls";
import { getAuthInfoFromStorage, setAuthInfoInStorage } from './localStorageUtils';

import { usePostHog } from 'posthog-js/react'


export const AuthHandler = () => {
    const posthog = usePostHog()
    const [refreshTimerID, setRefreshTimerID] = useState(null);
    const [authInfo, setAuthInfo] = useState({});

    const Tracking = {
        "identifyUser": (userId, options={}) => posthog?.identify(userId, options),
        "logAction": (action) => posthog?.capture(action)

    }

    //Runs once on load of app: 
    //Gets auth info from storage and sets auth info accordingly
    useEffect(() => {
        setAuthInfo(() => getAuthInfoFromStorage());
    }, [])

    const signInHandler = async (email, password) => {
        Tracking.logAction("Attempted sign in");
        var res = await signIn(email, password);
        if (res.user && res.tokens) {
          Tracking.identifyUser(res.user.id);
          Tracking.logAction("Signed in");
        }
        setAuthInfo({'user': res.user, 'tokens': res.tokens})
        return res;
    }

    const createAccountHandler = async (fullName, email, password) => {
        Tracking.logAction("Attempted create account");
        var res = await createAccount(fullName, email, password);
        if (res.user && res.tokens) {
          Tracking.identifyUser(res.user.id, {"email": res.user.email});
          Tracking.logAction("Created account");
        }
        setAuthInfo({'user': res.user, 'tokens': res.tokens})
        return res;
      }

    

    //When auth info is updated, if auth info has been set we set all chats
    //We also log the user in to tracking
    useEffect(() => {
    if (authInfo.user && authInfo.tokens) {
      Tracking.identifyUser(authInfo.user.id);
    }
    }, [authInfo])

    //If authInfo has been updated, we store it in memory
    //Then we create a new refresh token request and time it for slightly before the time the access token is set to expire
    useEffect(() => {
        if (Object.keys(authInfo).length !== 0) {
            setAuthInfoInStorage(authInfo);

        var currentmstime = new Date().getTime()
        var newrefreshtime = new Date(authInfo.tokens.access.expires).getTime();

        if (currentmstime >= newrefreshtime) {
            setAuthInfo({});
            setAuthInfoInStorage({});
            if (refreshTimerID)
                clearTimeout(refreshTimerID);
            setRefreshTimerID(null);
            return
        }

        const timedelta = Math.floor((newrefreshtime - currentmstime) * 2/3)

        var timerID = setTimeout(() => {
            refreshAuthentication(authInfo.tokens.refresh.token).then(response => {
                if (response.access && response.refresh) {
                    Tracking.logAction("Refreshed authentication")
                    setAuthInfo(authInfo => ({
                    ...authInfo,
                    ...{
                        'tokens': {'access': response.access, 'refresh': response.refresh}
                    }
                    }));
                }
                });
            }, timedelta);

        setRefreshTimerID(
            timerID
        );
      
        }
    }, [authInfo])



    return {
        authInfo,
        setAuthInfo,
        signInHandler,
        createAccountHandler
    };
};
