import React from "react";
import ReactDOMClient from "react-dom/client";
import { App } from "./App";

import { PostHogProvider} from 'posthog-js/react'

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  }

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);


root.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);
