import React, { useEffect } from "react";
import { ChatMessage } from "./ChatMessage/ChatMessage";

import "./chatbox.css";

export const ChatBox = ({
    avatarComponent,
    messages,
    chatboxRef,
    scrollAmount,
}) => {
    useEffect(() => {
        if (chatboxRef.current) {
            const maxScroll =
                chatboxRef.current.scrollHeight -
                chatboxRef.current.clientHeight;

            // set scroll position based on the slider value
            chatboxRef.current.scrollTop = (maxScroll * scrollAmount) / 100;
        }
    }, [messages, scrollAmount, chatboxRef]);

    return (
        <div className="chatbox" ref={chatboxRef}>
            {messages.map((msg, index) => (
                <ChatMessage
                    key={index}
                    msg={msg}
                    avatarComponent={avatarComponent}
                />
            ))}
        </div>
    );
};
