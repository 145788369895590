import React, { useState } from "react";

import DefaultBadge from "../../icons/img/badge.png";
import DefaultHat from "../../icons/img/hat.png";

import DefaultAvatar from "../../icons/img/student-default-female.png";

import { Avatar } from "../Avatar/Avatar";
import { MenuBar } from "../MenuBar/MenuBar";
import { PopUps } from "../PopUps/PopUps";

import "./profile.css";

export const Profile = () => {
    const { avatar, setAvatar } = Avatar();
    const [showPopup, setShowPopup] = useState(false);

    return (
        <div className="frame">
            <div className="main-content">
                <MenuBar />

                <div className="profile-content">
                    <div className="avatar-content">
                        <div className="edit-avatar">
                            <div className="avatar">
                                <img
                                    className="body"
                                    src={avatar.body || DefaultAvatar}
                                    alt="Avatar Body"
                                />
                                {avatar.head && (
                                    <img
                                        className="head"
                                        src={avatar.head || null}
                                        alt="Avatar Hat"
                                    />
                                )}
                            </div>

                            <button
                                className="edit-button"
                                onClick={() => setShowPopup(true)}
                            >
                                Edit Avatar
                            </button>
                        </div>

                        {showPopup && (
                            <PopUps
                                currentPopup={"editAvatar"}
                                setShowPopup={setShowPopup}
                                avatarState={avatar}
                                setAvatarState={setAvatar}
                                handleClosePopup={() => setShowPopup(false)}
                            />
                        )}

                        <div className="names">
                            <h1 className="name">Alex S.</h1>

                            <div className="titles">
                                <img
                                    className="badge"
                                    src={DefaultBadge}
                                    alt="Badge"
                                />

                                <h2 className="title">Rockstar Scientist</h2>
                            </div>
                        </div>

                        <h3 className="number-questions">10</h3>
                        <h4 className="description-questions">
                            Questions Answered Since Yesterday
                        </h4>
                    </div>

                    <div className="customize-content">
                        <div className="collection all-badges">
                            <h5 className="customize">Badges</h5>
                            <div className="customize-scrollable">
                                <div className="badge-item">
                                    <img src={DefaultBadge} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultBadge} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultBadge} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultBadge} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultBadge} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultBadge} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultBadge} alt="Badge" />
                                </div>
                                {/* more badge items */}
                            </div>
                        </div>

                        <div className="collection all-titles">
                            <h5 className="customize">Titles</h5>
                            <div className="customize-scrollable">
                                <div className="title-item">
                                    <h2 className="title">
                                        Rockstar Scientist
                                    </h2>
                                </div>
                                <div className="title-item">
                                    <h2 className="title">
                                        Rockstar Scientist
                                    </h2>
                                </div>
                                <div className="title-item">
                                    <h2 className="title">
                                        Rockstar Scientist
                                    </h2>
                                </div>
                                <div className="title-item">
                                    <h2 className="title">
                                        Rockstar Scientist
                                    </h2>
                                </div>
                                <div className="title-item">
                                    <h2 className="title">
                                        Rockstar Scientist
                                    </h2>
                                </div>
                                <div className="title-item">
                                    <h2 className="title">
                                        Rockstar Scientist
                                    </h2>
                                </div>
                                <div className="title-item">
                                    <h2 className="title">
                                        Rockstar Scientist
                                    </h2>
                                </div>
                                {/* more title items */}
                            </div>
                        </div>

                        <div className="collection all-accessories">
                            <h5 className="customize">Accessories</h5>
                            <div className="customize-scrollable">
                                <div className="badge-item">
                                    <img src={DefaultHat} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultHat} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultHat} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultHat} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultHat} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultHat} alt="Badge" />
                                </div>
                                <div className="badge-item">
                                    <img src={DefaultHat} alt="Badge" />
                                </div>
                                {/* more accessory items */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
