import React from "react";
import { useNavigate } from "react-router-dom";

import Badge from "../../../icons/img/badge.png";
import Hat from "../../../icons/img/hat.png";
import Stars from "../../../icons/img/stars.png";

import "../popups.css";

export const LevelPopUp = ({ handleOnClose, handleAvatarPopUp }) => {
    const navigate = useNavigate();

    const goToProfile = () => {
        navigate("/profile");
    };

    return (
        <>
            <div className="rewards">
                <div className="reward-section">
                    <p className="reward-title">Adventure Badge</p>
                    <img src={Badge} alt="Badge" />
                    <button className="reward-button" onClick={goToProfile}>
                        Apply Now!
                    </button>
                </div>
                <div className="encouragement-section">
                    <p className="encouragement-title">Rockstar Scientist!</p>
                    <p className="encouragement-detail">
                        You asked 3 more questions than last week!
                    </p>

                    <img className="star1" src={Stars} alt="Stars" />
                    <img className="star2" src={Stars} alt="Stars" />
                </div>
                <div className="reward-section">
                    <p className="reward-title">Wizard Hat</p>
                    <img src={Hat} alt="Hat" />
                    <button
                        className="reward-button"
                        onClick={handleAvatarPopUp}
                    >
                        Apply Now!
                    </button>
                </div>
            </div>

            <button className="close-button" onClick={handleOnClose}>
                Continue
            </button>
        </>
    );
};
