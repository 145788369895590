import TadaSound from "../sounds/tada.mp3";
import TwinkleSparkleSound from "../sounds/twinkle-sparkle.mp3";

export const SoundTypes = {
    LEVEL_UP: "level_up",
    POINTS: "points",
    // add more sound types as needed
};

const soundMap = {
    [SoundTypes.LEVEL_UP]: TadaSound,
    [SoundTypes.POINTS]: TwinkleSparkleSound,
    // add more sounds here
};

// function to play sound based on type
export const playSound = (soundType) => {
    const soundFile = soundMap[soundType];

    if (!soundFile) {
        console.error(`Sound Type "${soundType}" Not Found!`);
        return;
    }

    const audio = new Audio(soundFile);
    audio.play().catch((error) => {
        console.error("Error Playing Sound:", error);
    });
};
