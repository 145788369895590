import React, { forwardRef, useImperativeHandle, useRef } from "react";

export const ImageHandler = forwardRef((props, ref) => {
    const inputRef = useRef(null); // create a ref for the file input

    // function to handle icon click and trigger file input
    const handleIconClick = () => {
        if (inputRef.current) {
            inputRef.current.click(); // trigger file input click
        }
    };

    // function to handle changes in the file input
    const handleChange = (event) => {
        const files = Array.from(event.target.files); // get all files from input

        if (files.length > 0) {
            const imageUrls = []; // array to store image URLs

            files.forEach((file) => {
                const reader = new FileReader(); // create a FileReader instance

                reader.onloadend = () => {
                    imageUrls.push(reader.result); // push each image URL to the array

                    if (imageUrls.length === files.length) {
                        props.onImageUpload(imageUrls); // send all image URLs back to parent
                    }
                };

                reader.readAsDataURL(file); // read file as data URL
            });
        }
    };

    // expose methods to parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
        handleIconClick, // expose handleIconClick method

        clearInput: () => {
            if (inputRef.current) {
                inputRef.current.value = ""; // clear the file input after upload
            }
        },
    }));

    return (
        <div>
            <input
                type="file"
                accept="image/*" // accept only image files
                onChange={handleChange} // handle file input changes
                style={{ display: "none" }} // hide the input
                ref={inputRef} // assign ref to input
                multiple // allow multiple file uploads
            />
        </div>
    );
});
