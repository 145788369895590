import React, { useEffect, useRef, useState } from "react";

import { AuthHandler } from "../../utils/AuthHandler";
import { getSession, sendChat } from "../../utils/backendCalls";
import { playSound, SoundTypes } from "../../utils/SoundHandler";

import {
    Avatar,
    ChatBox,
    MenuBar,
    PopUps,
    QuestionPrompts,
    Title,
    UserInput,
} from "../index";

import "./mainscreen.css";

export const MainScreen = () => {
    const chatboxRef = useRef(null);

    // State management
    const [isLogin, setIsLogin] = useState(true);

    const [currentPoints, setCurrentPoints] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    // state for tracking user's level, messages, earned points, popups, and avatar
    const [levelup, setLevelUp] = useState(0);
    const [allChats, setAllChats] = useState([]);
    const [messages, setMessages] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [qIndex, setQIndex] = useState(0);
    const [earnedPoints, setEarnedPoints] = useState(0);
    const [earnedStars, setEarnedStars] = useState(0);

    const [currentPopup, setCurrentPopup] = useState("login");
    const [keepLogin, setKeepLogin] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const [showQuestionPrompts, setShowQuestionPrompts] = useState(false);
    const [messageCount, setMessageCount] = useState(0);
    const [showChatbox, setShowChatbox] = useState(false);

    const [isGenerating, setIsGenerating] = useState(false);

    const {
        avatar,
        setAvatar,
        resetAvatar,
        renderIconAvatar,
        renderChatAvatar,
    } = Avatar(); // Instantiate Avatar

    const { authInfo, setAuthInfo, signInHandler, createAccountHandler } =
        AuthHandler();

    const accountWrapper = async (fullName, email, password) => {
        setKeepLogin(true);
        try {
            return await createAccountHandler(fullName, email, password);
        } catch (error) {
            setKeepLogin(false);
            throw error;
        }
    };

    useEffect(() => {
        setAllChats(questions.map((x) => []));
        setMessages([]);
    }, [questions]);

    useEffect(() => {
        setMessages(allChats[qIndex]);
    }, [qIndex]);

    useEffect(() => {
        if (messages?.length !== 0)
            setAllChats((oldChats) => [
                ...oldChats.slice(0, qIndex),
                messages,
                ...oldChats.slice(qIndex + 1),
            ]);
    }, [messages]);

    useEffect(() => {
        const storedQuestions = localStorage.getItem("questions");
        if (
            storedQuestions != null &&
            storedQuestions !== JSON.stringify(questions)
        ) {
            setQuestions(JSON.parse(storedQuestions));
            setShowQuestionPrompts(false);
        }

        async function fetchQuestions() {
            if (authInfo.user) {
                var session = await getSession(authInfo.user.id);
                var questions = session.questions;
                setQuestions(questions);
                localStorage.setItem("questions", JSON.stringify(questions));
                setShowQuestionPrompts(false);
            }
        }
        fetchQuestions();
    }, [authInfo]);

    // Function to reset chatbox and variables
    const resetChatbox = () => {
        setMessages([]);
        setCurrentPoints(0);
        setEarnedStars(0);
        setTotalPoints(0);
        setEarnedPoints(0);
        setShowPopup(false);
        setShowQuestionPrompts(false);
        setShowChatbox(false);
        resetAvatar(setAvatar);
        setIsGenerating(false);
        setIsLogin(true);
    };

    // Send message logic
    const handleSend = () => {
        const inputText = document.querySelector(".ask-text").value;
        if (inputText) {
            sendMessage(inputText, false); // Send message
            document.querySelector(".ask-text").value = ""; // Clear input
            generateResponse(inputText);
        }
    };

    // Function to send message with points update
    const sendMessage = (message, isSample, isImage = false) => {
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: message, isSample, isResponse: false, isImage },
        ]);
        setShowQuestionPrompts(false);

        let pointsEarned = !isSample ? 10 : 5;
        const newCurrentPoints = Math.min(currentPoints + pointsEarned, 30);
        setCurrentPoints(newCurrentPoints);
        setTotalPoints((prevTotalPoints) => prevTotalPoints + pointsEarned);
        setEarnedPoints(pointsEarned);

        setShowChatbox(true);
        if (messageCount % 2 === 0) {
            playSound(SoundTypes.POINTS);
        }
        setMessageCount((prevCount) => prevCount + 1);

        if (newCurrentPoints === 30 && earnedStars < 10) {
            setEarnedStars((prevStars) => {
                const newStarCount = prevStars + 1;
                if (newStarCount === 10 && !showPopup) {
                    setCurrentPopup("rewards");
                    setShowPopup(true);
                    playSound(SoundTypes.LEVEL_UP);
                }
                return newStarCount;
            });
            setCurrentPoints(0);
        }

        if (earnedStars === 10 && !showPopup) {
            setCurrentPopup("rewards");
            setShowPopup(true);
            playSound(SoundTypes.LEVEL_UP);
        }
    };

    // Function to generate response
    const generateResponse = async (inputText = undefined) => {
        setIsGenerating(true);
        setMessages((prevMessages) => [
            ...prevMessages,
            {
                text: "",
                isSample: false,
                isResponse: true,
                isLoading: true,
                isImage: false,
            },
        ]);

        // hard coded for now, but pass in props
        var responseText =
            "The appearance of bubbles is evidence of a gas-forming chemical reaction.";

        console.dir(messages)

        if (inputText !== undefined)
            var responseText = await sendChat(
                inputText,
                messages.filter((x) => x["text"] !== inputText),
                questions && questions.length > 0
                    ? questions[qIndex]
                    : "Create a chemical reaction with baking soda and vinegar",
                authInfo.user.id
            );

        var highlightIndices = [];

        /*[
            [18, 25],
            [43, 54],
            [64, 72],
        ];*/
        var highlightColors = [];

        //["red", "blue", "green"];

        setMessages((prevMessages) => {
            const lastIndex = prevMessages.length - 1;
            return prevMessages.map((message, index) =>
                index === lastIndex
                    ? { ...message, text: responseText, isLoading: false }
                    : message
            );
        });

        setIsGenerating(false);
    };

    useEffect(() => {
        if (!authInfo.user || !authInfo.tokens) {
            console.log("ITZY");
            setIsLogin(true);
            setCurrentPopup("login");
        } else {
            console.log("AESPA");
            setIsLogin(false);
        }
    }, [authInfo]);

    useEffect(() => {
        if (chatboxRef.current) {
            chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (earnedPoints > 0) {
            const timeoutId = setTimeout(() => setEarnedPoints(0), 5000);
            return () => clearTimeout(timeoutId);
        }
    }, [earnedPoints]);

    return (
        <div className="frame">
            {!isLogin && !keepLogin ? (
                <div className="main-content">
                    <MenuBar />
                    <div className="screen-content">
                        {questions.length === 0 ? null : (
                            <Title
                                currentPoints={currentPoints}
                                earnedStars={earnedStars}
                                totalPoints={totalPoints}
                                levelup={levelup}
                                questions={questions}
                                qIndex={qIndex}
                                setQIndex={setQIndex}
                            />
                        )}

                        {showQuestionPrompts && (
                            <QuestionPrompts
                                sendMessage={sendMessage}
                                generateResponse={generateResponse}
                            />
                        )}
                        <div className="dynamic-content">
                            {showChatbox && (
                                <ChatBox
                                    avatarComponent={renderChatAvatar()}
                                    messages={messages}
                                    chatboxRef={chatboxRef}
                                    scrollAmount={null}
                                />
                            )}
                        </div>

                        <UserInput
                            setShowPopup={setShowPopup}
                            setCurrentPopup={setCurrentPopup}
                            avatarComponent={renderIconAvatar()}
                            earnedPoints={earnedPoints}
                            handleSend={handleSend}
                            isGenerating={isGenerating}
                        />

                        {showPopup && (
                            <PopUps
                                setShowPopup={setShowPopup}
                                currentPopup={currentPopup}
                                setCurrentPopup={setCurrentPopup}
                                handleReset={resetChatbox}
                                avatarState={avatar}
                                setAvatarState={setAvatar}
                                sendMessage={sendMessage}
                                generateResponse={generateResponse}
                                setIsLogin={setIsLogin}
                                signInHandler={signInHandler}
                                createAccountHandler={accountWrapper}
                                setKeepLogin={setKeepLogin}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <PopUps
                    currentPopup={currentPopup}
                    setCurrentPopup={setCurrentPopup}
                    setIsLogin={setIsLogin}
                    avatarState={avatar}
                    setAvatarState={setAvatar}
                    signInHandler={signInHandler}
                    createAccountHandler={accountWrapper}
                    setKeepLogin={setKeepLogin}
                />
            )}
        </div>
    );
};
