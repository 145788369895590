import { AvatarPopUp } from "../components/PopUps/AvatarPopUp/AvatarPopUp";
import { EditAvatarPopUp } from "../components/PopUps/AvatarPopUp/EditAvatarPopUp/EditAvatarPopUp";
import { CameraPopUp } from "../components/PopUps/CameraPopUp/CameraPopUp";
import { LevelPopUp } from "../components/PopUps/LevelPopUp/LevelPopUp";
import { LoginPopUp } from "../components/PopUps/LoginPopUp/LoginPopUp";

import CollabriaFullLogo from "../icons/img/collabria-full.png";

export const PopUpHandler = (
    handleClosePopup,
    handleRewardsPopUp,
    handleAvatarPopUp,
    handleCameraPopUp,
    handleLoginPopUp,
    handleEditAvatarPopUp,
    handleReset,

    avatarState,
    setAvatarState,

    sendMessage,
    generateResponse,
    handleFileUpload,

    setIsLogin,
    loginTitle,
    setLoginTitle,

    signInHandler,
    createAccountHandler,
    setKeepLogin
) => {
    return {
        rewards: {
            title: "Well Done!",
            content: (
                <LevelPopUp
                    handleOnClose={handleReset}
                    handleAvatarPopUp={handleAvatarPopUp}
                />
            ),
        },
        avatar: {
            title: "Avatar Display",
            content: (
                <AvatarPopUp
                    handleOnBack={handleRewardsPopUp}
                    avatarState={avatarState}
                    setAvatarState={setAvatarState}
                />
            ),
        },
        camera: {
            title: "Camera Display",
            content: (
                <CameraPopUp
                    handleOnClose={handleClosePopup}
                    sendMessage={sendMessage}
                    generateResponse={generateResponse}
                    handleFileUpload={handleFileUpload}
                />
            ),
        },
        login: {
            title: (
                <div className="login-header">
                    <img
                        src={CollabriaFullLogo}
                        alt="Collabria Full Logo"
                        className="collabria-full-logo"
                    />
                    {loginTitle}
                </div>
            ),
            content: (
                <LoginPopUp
                    setIsLogin={setIsLogin}
                    setPopupTitle={setLoginTitle}
                    avatarState={avatarState}
                    setAvatarState={setAvatarState}
                    signInHandler={signInHandler}
                    createAccountHandler={createAccountHandler}
                    setKeepLogin={setKeepLogin}
                />
            ),
        },
        editAvatar: {
            title: "Edit Avatar",
            content: (
                <EditAvatarPopUp
                    handleClosePopup={handleClosePopup}
                    avatarState={avatarState}
                    setAvatarState={setAvatarState}
                />
            ),
        },
        default: {
            title: "",
            content: handleClosePopup,
        },
    };
};
