import React from "react";

import { FaStar } from "react-icons/fa";
import "./Stars.css";

export const Stars = () => {
    return (
        <div className="star-container">
            <FaStar className="big-star score-star" />

            <div className="small-stars">
                {[...Array(5)].map((_, i) => (
                    <FaStar key={i} className={`tiny-star star-${i + 1}`} />
                ))}
            </div>
        </div>
    );
};
