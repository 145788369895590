import React from "react";
import { useNavigate } from "react-router-dom";

import "./student.css";

import Chaeryeong from "../../../images/students/chaeryeong.webp";
import Lia from "../../../images/students/lia.webp";
import Ryujin from "../../../images/students/ryujin.webp";
import Yeji from "../../../images/students/yeji.webp";
import Yuna from "../../../images/students/yuna.webp";

import Exclamation from "../../../icons/img/exclamation-point.png";
import Question from "../../../icons/img/question-mark.png";

import High from "../../../icons/img/engagement/high.jpg";
import Low from "../../../icons/img/engagement/low.jpg";
import Medium from "../../../icons/img/engagement/medium.jpg";
import VeryHigh from "../../../icons/img/engagement/very-high.jpg";
import VeryLow from "../../../icons/img/engagement/very-low.jpg";

import { Carrot } from "../../../icons/svg/Carrot/Carrot";

// object to hold all student images
const studentImages = {
    yeji: Yeji,
    lia: Lia,
    ryujin: Ryujin,
    chaeryeong: Chaeryeong,
    yuna: Yuna,
};

// function to format the date and time
const formatLastActive = (lastActive) => {
    const date = new Date(lastActive);
    return date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};

export const Student = ({ student }) => {
    const navigate = useNavigate();

    const goToStudentProfile = () => {
        navigate(`${student.profileLink}`);
    };

    // function to find the engagement image based on engagement status
    const engagementImage = () => {
        switch (student.engagementStatus) {
            case "Very High":
                return (
                    <img
                        className="engagement"
                        src={VeryHigh}
                        alt="Very High Engagement"
                    />
                );
            case "High":
                return (
                    <img
                        className="engagement"
                        src={High}
                        alt="High Engagement"
                    />
                );
            case "Medium":
                return (
                    <img
                        className="engagement"
                        src={Medium}
                        alt="Medium Engagement"
                    />
                );
            case "Low":
                return (
                    <img
                        className="engagement"
                        src={Low}
                        alt="Low Engagement"
                    />
                );
            case "Very Low":
                return (
                    <img
                        className="engagement"
                        src={VeryLow}
                        alt="Very Low Engagement"
                    />
                );
            default:
                return null; // return nothing for undefined engagement status
        }
    };

    // function to find the alert image based on hasAlert value
    const alertImage = () => {
        switch (student.hasAlert) {
            case "question":
                return (
                    <img
                        className="alert"
                        src={Question}
                        alt="Question Alert"
                    />
                );
            case "danger":
                return (
                    <img
                        className="alert"
                        src={Exclamation}
                        alt="Exclamation Alert"
                    />
                );
            case "none":
                return null; // return nothing for "none"
            default:
                return null; // return nothing by default
        }
    };

    return (
        <li className="student-item" onClick={goToStudentProfile}>
            <div className="student-content">
                <div className="profile-picture-content">
                    <img
                        className="profile-picture"
                        src={studentImages[student.name.toLowerCase()]}
                        alt={student.name}
                    />
                </div>

                <div className="status-content">
                    <div className="name-content">
                        <h2 className="student-name">{student.name}</h2>
                        {engagementImage()}
                    </div>

                    <p className="active">
                        Last Active:{" "}
                        <em>{formatLastActive(student.lastActive)}</em>
                    </p>
                </div>
            </div>

            <div className="alert-content">
                {alertImage()}
                <Carrot className="carrot-profile" />
            </div>
        </li>
    );
};
