import React, { useEffect, useState } from "react";

import { AvatarHandler } from "../../../utils/AvatarHandler";

import DefaultAvatarFemale from "../../../icons/img/student-default-female.png";
import DefaultAvatarMale from "../../../icons/img/student-default-male.png";
import Student10 from "../../../icons/img/student10.png";
import Student3 from "../../../icons/img/student3.png";
import Student4 from "../../../icons/img/student4.png";
import Student5 from "../../../icons/img/student5.png";
import Student6 from "../../../icons/img/student6.png";
import Student7 from "../../../icons/img/student7.png";
import Student8 from "../../../icons/img/student8.png";
import Student9 from "../../../icons/img/student9.png";

import "../popups.css";

export const LoginPopUp = ({
    setIsLogin,
    setPopupTitle,
    avatarState,
    setAvatarState,
    signInHandler,
    createAccountHandler,
    setKeepLogin,
}) => {
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const [isAccountCreated, setIsAccountCreated] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    // Validate email using regex
    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    // Validate password strength: 8+ chars, 1 uppercase, 1 number, 1 special character
    const validatePassword = (password) => {
        const passwordPattern =
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordPattern.test(password);
    };

    const handleLogin = async () => {
        // Uncomment this section to enable error checking

        // let valid = true;
        // if (!validateEmail(email)) {
        //     setEmailError("Please enter a valid email.");
        //     valid = false;
        // } else {
        //     setEmailError("");
        // }
        // if (!validatePassword(password)) {
        //     setPasswordError(
        //         "Password must be at least 8 characters, include 1 uppercase letter, 1 number, and 1 special character."
        //     );
        //     valid = false;
        // } else {
        //     setPasswordError("");
        // }

        // Proceed with login logic regardless of validation
        try {
            await signInHandler(email, password);
            setIsLogin(false);
        } catch (error) {
            alert(error);
        }

        // setIsLogin(false);
    };

    const handleCreateAccount = async () => {
        // Uncomment this section to enable error checking

        // let valid = true;
        // if (!validateEmail(email)) {
        //     setEmailError("Please enter a valid email.");
        //     valid = false;
        // } else {
        //     setEmailError("");
        // }
        // if (!validatePassword(password)) {
        //     setPasswordError(
        //         "Password must be at least 8 characters, include 1 uppercase letter, 1 number, and 1 special character."
        //     );
        //     valid = false;
        // } else {
        //     setPasswordError("");
        // }

        // Proceed with account creation logic regardless of validation
        try {
            await createAccountHandler(fullName, email, password);
            setIsAccountCreated(true);
        } catch (error) {
            alert(error);
        }

        // setIsAccountCreated(true);
    };

    const { applyBody } = AvatarHandler();

    const handleAvatarClick = (newBody) => {
        applyBody(setAvatarState, avatarState, newBody);
        setIsLogin(false);
        setKeepLogin(false);
        // window.location.reload();
    };

    useEffect(() => {
        if (isAccountCreated) {
            setPopupTitle("Choose Your Avatar");
        } else {
            setPopupTitle(isCreatingAccount ? "Create Account" : "Login");
        }
    }, [isCreatingAccount, isAccountCreated, setPopupTitle]); // callback

    return (
        <div className="login-content">
            {isAccountCreated ? (
                <div className="all-avatars">
                    <div className="customize-scrollable">
                        <div
                            className="avatar-item"
                            onClick={() => handleAvatarClick(DefaultAvatarMale)}
                        >
                            <img src={DefaultAvatarMale} alt="Avatar (Male)" />
                        </div>
                        <div
                            className="avatar-item"
                            onClick={() =>
                                handleAvatarClick(DefaultAvatarFemale)
                            }
                        >
                            <img
                                src={DefaultAvatarFemale}
                                alt="Avatar (Female)"
                            />
                        </div>
                        <div
                            className="avatar-item"
                            onClick={() => handleAvatarClick(Student3)}
                        >
                            <img src={Student3} alt="Avatar (Male)" />
                        </div>
                        <div
                            className="avatar-item"
                            onClick={() => handleAvatarClick(Student4)}
                        >
                            <img src={Student4} alt="Avatar (Female)" />
                        </div>
                        <div
                            className="avatar-item"
                            onClick={() => handleAvatarClick(Student5)}
                        >
                            <img src={Student5} alt="Avatar (Male)" />
                        </div>
                        <div
                            className="avatar-item"
                            onClick={() => handleAvatarClick(Student6)}
                        >
                            <img src={Student6} alt="Avatar (Female)" />
                        </div>
                        <div
                            className="avatar-item"
                            onClick={() => handleAvatarClick(Student7)}
                        >
                            <img src={Student7} alt="Avatar (Male)" />
                        </div>
                        <div
                            className="avatar-item"
                            onClick={() => handleAvatarClick(Student8)}
                        >
                            <img src={Student8} alt="Avatar (Female)" />
                        </div>
                        <div
                            className="avatar-item"
                            onClick={() => handleAvatarClick(Student9)}
                        >
                            <img src={Student9} alt="Avatar (Male)" />
                        </div>
                        <div
                            className="avatar-item"
                            onClick={() => handleAvatarClick(Student10)}
                        >
                            <img src={Student10} alt="Avatar (Female)" />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="form-content">
                    {!isCreatingAccount ? (
                        // Login Form
                        <div className="signin-content">
                            <div className="login-info">
                                <div className="input-content">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        className={
                                            emailError ? "input-error" : ""
                                        }
                                    />
                                    {emailError && (
                                        <p className="error-text">
                                            {emailError}
                                        </p>
                                    )}
                                </div>
                                <div className="input-content">
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        className={
                                            passwordError ? "input-error" : ""
                                        }
                                    />
                                    {passwordError && (
                                        <p className="error-text">
                                            {passwordError}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <button onClick={handleLogin}>Login</button>

                            <div className="toggle-option">
                                <span>Don't have an account? </span>
                                <a
                                    href="#"
                                    onClick={() => setIsCreatingAccount(true)}
                                >
                                    Create Account
                                </a>
                            </div>
                        </div>
                    ) : (
                        // Sign-Up Form
                        <div className="create-account-content">
                            <div className="login-info">
                                <div className="input-content">
                                    <input
                                        type="text"
                                        placeholder="Full Name"
                                        value={fullName}
                                        onChange={(e) =>
                                            setFullName(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="input-content">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        className={
                                            emailError ? "input-error" : ""
                                        }
                                    />
                                    {emailError && (
                                        <p className="error-text">
                                            {emailError}
                                        </p>
                                    )}
                                </div>
                                <div className="input-content">
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        className={
                                            passwordError ? "input-error" : ""
                                        }
                                    />
                                    {passwordError && (
                                        <p className="error-text">
                                            {passwordError}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <button onClick={handleCreateAccount}>
                                Create Account
                            </button>

                            <div className="toggle-option">
                                <span>Already have an account? </span>
                                <a
                                    href="#"
                                    onClick={() => setIsCreatingAccount(false)}
                                >
                                    Login
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
