import React from "react";

import { AskCollabria } from "../AskCollabria/AskCollabria";
import { MenuBar } from "../MenuBar/MenuBar";

import "./classroom.css";

import Chaeryeong from "../../images/students/chaeryeong.webp";
import Lia from "../../images/students/lia.webp";
import Ryujin from "../../images/students/ryujin.webp";
import Yeji from "../../images/students/yeji.webp";
import Yuna from "../../images/students/yuna.webp";

// object to hold all student images
const studentImages = {
    yeji: Yeji,
    lia: Lia,
    ryujin: Ryujin,
    chaeryeong: Chaeryeong,
    yuna: Yuna,
};

// hardcoded for now maybe store in a ".csv" file?
const studentList = [
    {
        id: 1,
        name: "Yeji",
        profilePicture: "yeji.webp",
        engagementStatus: "Very High",
        lastActive: "2023-04-11T19:42:00",
        hasAlert: "none",
        profileLink: "/profile/yeji",
        x: 85,
        y: 25,
    },
    {
        id: 2,
        name: "Lia",
        profilePicture: "lia.webp",
        engagementStatus: "Medium",
        lastActive: "2023-04-10T16:30:00",
        hasAlert: "question",
        profileLink: "/profile/lia",
        x: 50,
        y: 60,
    },
    {
        id: 3,
        name: "Ryujin",
        profilePicture: "ryujin.webp",
        engagementStatus: "Low",
        lastActive: "2023-04-09T08:15:00",
        hasAlert: "danger",
        profileLink: "/profile/ryujin",
        x: 20,
        y: 80,
    },
    {
        id: 4,
        name: "Chaeryeong",
        profilePicture: "chaeryeong.webp",
        engagementStatus: "High",
        lastActive: "2023-04-11T12:25:00",
        hasAlert: "none",
        profileLink: "/profile/chaeryeong",
        x: 60,
        y: 40,
    },
    {
        id: 5,
        name: "Yuna",
        profilePicture: "yuna.webp",
        engagementStatus: "Very Low",
        lastActive: "2023-04-11T14:10:00",
        hasAlert: "none",
        profileLink: "/profile/yuna",
        x: 20,
        y: 10,
    },
];

export const Classroom = () => {
    return (
        <div className="frame">
            <div className="main-content">
                <MenuBar />

                <div className="classroom-content">
                    <div className="content-header">
                        <h1 className="classroom-name">Classroom</h1>
                        <AskCollabria />
                    </div>

                    {/* Grid */}
                    <div className="quadrants">
                        {/* Labels */}
                        {/* Y-Axis Label: Focused (Top) and Distracted (Bottom) */}
                        <div className="y-axis top">Focused</div>
                        <div className="y-axis bottom">Distracted</div>

                        {/* X-Axis Label: Confused (Left) and Understood (Right) */}
                        <div className="x-axis left">Confused</div>
                        <div className="x-axis right">Understood</div>

                        {/* Quadrants */}
                        {/* Top-Left: Confused & Focused */}
                        <div className="quadrant top-left">
                            <span className="emoji">🤔</span>
                        </div>

                        {/* Top-Right: Understood & Focused */}
                        <div className="quadrant top-right">
                            <span className="emoji">🤓</span>
                        </div>

                        {/* Bottom-Left: Confused & Distracted */}
                        <div className="quadrant bottom-left">
                            <span className="emoji">😵</span>
                        </div>

                        {/* Bottom-Right: Understood & Distracted */}
                        <div className="quadrant bottom-right">
                            <span className="emoji">🥱</span>
                        </div>

                        {/* Student Icons */}
                        {studentList.map((student) => (
                            <div
                                key={student.id}
                                className="student-profile"
                                style={{
                                    left: `${student.x}%`,
                                    top: `${student.y}%`,
                                    position: "absolute",
                                }}
                            >
                                <img
                                    src={
                                        studentImages[
                                            student.profilePicture.replace(
                                                ".webp",
                                                ""
                                            )
                                        ]
                                    }
                                    alt={student.name}
                                    className="student-pic"
                                />
                                <span className="student-name">
                                    {student.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
