import React, { useEffect, useRef, useState } from "react";
import { LevelUp } from "../../icons/svg/LevelUp/LevelUp";
import "./title.css";

export const Title = ({
    levelUp,
    currentPoints,
    earnedStars,
    totalPoints,
    questions,
    qIndex,
    setQIndex,
}) => {
    const [fontSize, setFontSize] = useState("1.2rem");
    const questionRef = useRef(null);

    const percentage = Math.floor((currentPoints / 30) * 100);

    // const adjustFontSize = () => {
    //     if (questionRef.current) {
    //         const containerHeight = questionRef.current.clientHeight;
    //         const containerWidth = questionRef.current.clientWidth;
    //         const questionText =
    //             questions && questions.length > 0
    //                 ? questions[qIndex]
    //                 : "Create a chemical reaction with baking soda and vinegar";

    //         const canvas = document.createElement("canvas");
    //         const context = canvas.getContext("2d");

    //         // Dynamically calculate the max font size based on the length of the question
    //         const questionLength = questionText.length;

    //         console.log(questionLength);
    //         // const maxFontSize = Math.max(15 - questionLength * 0.5, 1);
    //         const maxFontSize = containerHeight / 16;

    //         let newFontSize = Math.min(
    //             containerHeight /
    //                 1.5 /
    //                 parseFloat(
    //                     getComputedStyle(document.documentElement).fontSize
    //                 ), // Convert to rem
    //             containerWidth /
    //                 (questionLength / 2) /
    //                 parseFloat(
    //                     getComputedStyle(document.documentElement).fontSize
    //                 ) // Convert to rem
    //         ); // Adjust based on the expected length of the text

    //         newFontSize = Math.min(newFontSize, maxFontSize); // Ensure it doesn't exceed max font size
    //         context.font = `${newFontSize}rem Montserrat, Helvetica`;

    //         let textWidth = context.measureText(questionText).width;

    //         // Adjust font size until the text fits both width and height
    //         while (
    //             textWidth > containerWidth ||
    //             newFontSize >
    //                 containerHeight /
    //                     parseFloat(
    //                         getComputedStyle(document.documentElement).fontSize
    //                     ) // Check against the max height in rem
    //         ) {
    //             newFontSize -= 0.01; // Decrease font size by 0.01rem for finer control
    //             context.font = `${newFontSize}rem Montserrat, Helvetica`;
    //             textWidth = context.measureText(questionText).width;

    //             // Ensure it doesn't shrink too much
    //             if (newFontSize <= 0.75) {
    //                 // Stop at a minimum font size of 0.75rem (approximately 12px)
    //                 break;
    //             }
    //         }

    //         setFontSize(`${newFontSize.toFixed(2)}rem`); // Set font size in rem with two decimals
    //     }
    // };

    const adjustFontSize = () => {
        if (questionRef.current) {
            const containerHeight = questionRef.current.clientHeight; // Container height in pixels
            const containerWidth = questionRef.current.clientWidth; // Container width in pixels
            const questionText =
                questions && questions.length > 0
                    ? questions[qIndex]
                    : "Create a chemical reaction with baking soda and vinegar";

            const questionLength = questionText.length;

            // Estimate the average width of a character (in pixels)
            const avgCharacterWidth = 8; // This can vary based on your font
            const lineHeight = 1.5; // Adjust as needed for line height

            // Calculate max font size based on container width
            const maxFontSizeByWidth =
                containerWidth / (questionLength * avgCharacterWidth);

            // Calculate max font size based on container height
            const numberOfLines = 2; // Number of lines you want to fit (can adjust based on your design)
            const maxFontSizeByHeight =
                containerHeight / (lineHeight * numberOfLines);

            // Use the smaller of the two calculated max font sizes
            let newFontSize = Math.min(maxFontSizeByWidth, maxFontSizeByHeight);

            console.log(newFontSize, "B");
            // Set font size in rem (1rem = 16px, so divide by 16)
            newFontSize = Math.max(newFontSize / 16, 0.75); // Ensure it doesn't go below 0.75rem
            console.log(newFontSize, "A");

            setFontSize(`${newFontSize}rem`); // Set font size in rem with two decimals
        }
    };

    useEffect(() => {
        //adjustFontSize(); // Adjust on mount and when questions change
    }, [questions, qIndex]); // Keep the dependencies to questions and qIndex

    /*useEffect(() => {
        window.addEventListener("resize", adjustFontSize); // Adjust on resize
        return () => {
            window.removeEventListener("resize", adjustFontSize);
        };
    }, []);*/
  
    // Adjust font size after changing question index
    const handleQIndexChange = (newIndex) => {
        setQIndex(newIndex);
        //adjustFontSize(); // Call adjustFontSize to update the font size immediately
    };

    return (
        <div className="title-content">
            <LevelUp
                percentage={percentage}
                earnedStars={earnedStars}
                totalPoints={totalPoints}
            />
            <p
                className="question-title"
                ref={questionRef}
                style={{ fontSize }} // This now uses rem
            >
                <span
                    className="navigation"
                    onClick={() => {
                        if (qIndex > 0) handleQIndexChange(qIndex - 1);
                    }}
                >
                    {questions && questions.length > 0 && qIndex > 0
                        ? "<"
                        : null}
                </span>
                <span className="question-text">
                    {questions && questions.length > 0
                        ? questions[qIndex]
                        : "Create a chemical reaction with baking soda and vinegar"}
                </span>
                <span
                    className="navigation"
                    onClick={() => {
                        if (qIndex < questions.length - 1)
                            handleQIndexChange(qIndex + 1);
                    }}
                >
                    {questions &&
                    questions.length > 0 &&
                    qIndex < questions.length - 1
                        ? ">"
                        : null}
                </span>
            </p>
        </div>
    );
};

// import React from "react";

// import { LevelUp } from "../../icons/svg/LevelUp/LevelUp";
// import "./title.css";

// export const Title = ({
//     levelUp,
//     currentPoints,
//     earnedStars,
//     totalPoints,
//     questions,
//     qIndex,
//     setQIndex,
// }) => {
//     const percentage = Math.floor((currentPoints / 30) * 100); // hardcoded max points to level up, but later have as prop

//     return (
//         <div className="title-content">
//             <LevelUp
//                 percentage={percentage}
//                 earnedStars={earnedStars}
//                 totalPoints={totalPoints}
//             />
//             <p className="question-title">
//                 <span onClick={() => setQIndex(qIndex - 1)}>
//                     {questions && questions.length > 0 && qIndex > 0
//                         ? "<"
//                         : null}
//                 </span>
//                 {questions && questions.length > 0
//                     ? questions[qIndex]
//                     : "Create a chemical reaction with baking soda and vinegar"}
//                 <span onClick={() => setQIndex(qIndex + 1)}>
//                     {questions &&
//                     questions.length > 0 &&
//                     qIndex < questions.length - 1
//                         ? ">"
//                         : null}
//                 </span>
//             </p>
//         </div>
//     );
// };
