import React from "react";

import { AvatarHandler } from "../../../../utils/AvatarHandler";

import DefaultAvatarFemale from "../../../../icons/img/student-default-female.png";
import DefaultAvatarMale from "../../../../icons/img/student-default-male.png";
import Student3 from "../../../../icons/img/student3.png";
import Student4 from "../../../../icons/img/student4.png";
import Student5 from "../../../../icons/img/student5.png";
import Student6 from "../../../../icons/img/student6.png";
import Student7 from "../../../../icons/img/student7.png";
import Student8 from "../../../../icons/img/student8.png";
import Student9 from "../../../../icons/img/student9.png";
import Student10 from "../../../../icons/img/student10.png";

export const EditAvatarPopUp = ({
    handleClosePopup,
    avatarState,
    setAvatarState,
}) => {
    const { applyBody } = AvatarHandler();

    const handleAvatarClick = (newBody) => {
        applyBody(setAvatarState, avatarState, newBody);
        handleClosePopup();
    };

    return (
        <div className="edit-avatar-content">
            <div className="all-avatars">
                <div className="customize-scrollable">
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(DefaultAvatarMale)}
                    >
                        <img src={DefaultAvatarMale} alt="Avatar (Male)" />
                    </div>
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(DefaultAvatarFemale)}
                    >
                        <img src={DefaultAvatarFemale} alt="Avatar (Female)" />
                    </div>
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(Student3)}
                    >
                        <img src={Student3} alt="Avatar (Male)" />
                    </div>
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(Student4)}
                    >
                        <img src={Student4} alt="Avatar (Female)" />
                    </div>
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(Student5)}
                    >
                        <img src={Student5} alt="Avatar (Male)" />
                    </div>
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(Student6)}
                    >
                        <img src={Student6} alt="Avatar (Female)" />
                    </div>
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(Student7)}
                    >
                        <img src={Student7} alt="Avatar (Male)" />
                    </div>
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(Student8)}
                    >
                        <img src={Student8} alt="Avatar (Female)" />
                    </div>
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(Student9)}
                    >
                        <img src={Student9} alt="Avatar (Male)" />
                    </div>
                    <div
                        className="avatar-item"
                        onClick={() => handleAvatarClick(Student10)}
                    >
                        <img src={Student10} alt="Avatar (Female)" />
                    </div>
                </div>
            </div>
        </div>
    );
};
