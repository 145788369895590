import Hat from "../icons/img/hat.png";

export const AvatarHandler = () => {
    const saveAvatar = (avatar) => {
        localStorage.setItem("avatar", JSON.stringify(avatar));
    };

    const loadAvatar = (setAvatar) => {
        const savedAvatar = JSON.parse(localStorage.getItem("avatar"));

        if (savedAvatar) {
            setAvatar(savedAvatar);
        } else {
            setAvatar(defaultAvatar);
        }
    };

    // reset avatar
    const resetAvatar = (setAvatar) => {
        localStorage.removeItem("avatar");
        setAvatar(defaultAvatar);
    };

    // default avatar
    const defaultAvatar = () => {
        return {
            head: null,
            body: null,
            legs: null,
            hat: null,

            faceWear: null,
            shoes: null,
            holding: null,
            body: null,
        };
    };

    // customize avatar
    const applyHat = (setAvatar, avatar) => {
        const newAvatar = { ...avatar, head: Hat };
        setAvatar(newAvatar);
        saveAvatar(newAvatar);
    };

    const applyBody = (setAvatar, avatar, newBody) => {
        const newAvatar = { ...avatar, body: newBody };
        setAvatar(newAvatar);
        saveAvatar(newAvatar);
    };

    // more customization here

    return {
        loadAvatar,
        resetAvatar,
        defaultAvatar,
        applyHat,
        applyBody,
    };
};
