import React, { useEffect, useRef, useState } from "react";
import { ImageHandler } from "../../../utils/ImageHandler";
import "../popups.css";

export const CameraPopUp = ({
    handleOnClose,
    sendMessage,
    generateResponse,
    handleFileUpload,
}) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const imageHandlerRef = useRef(null);

    const [webcamStream, setWebcamStream] = useState(null);
    const [isPhotoTaken, setIsPhotoTaken] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    const isMobileDevice = () => {
        return /(android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile)/i.test(
            navigator.userAgent
        );
    };

    const startWebcam = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
            });

            setWebcamStream(stream);

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (error) {
            console.error("Error Accessing Webcam:", error);
        }
    };

    const stopWebcam = () => {
        if (webcamStream) {
            webcamStream.getTracks().forEach((track) => track.stop());
            setWebcamStream(null);
        }
    };

    useEffect(() => {
        startWebcam();
        return () => stopWebcam();
    }, []);

    const takePhoto = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;

        if (canvas && video) {
            const context = canvas.getContext("2d");

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const capturedImageUrl = canvas.toDataURL("image/png");
            setImageUrl(capturedImageUrl);

            setIsPhotoTaken(true);
        }
    };

    const confirmPhoto = async () => {
        handleImageUpload([imageUrl]);
    };

    const retakePhoto = () => {
        setIsPhotoTaken(false);
        setImageUrl("");
        startWebcam();
    };

    const handleImageUpload = async (imageUrls) => {
        if (typeof handleFileUpload === "function") {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const file = new File(
                [blob],
                `captured_photo_${new Date().toISOString()}.png`,
                {
                    type: "image/png",
                }
            );

            const event = {
                target: {
                    files: [file],
                },
            };

            handleFileUpload(event);
            handleOnClose();
        } else {
            imageUrls.forEach((url) => {
                sendMessage(url, false, true);
            });

            handleOnClose();

            setTimeout(() => {
                generateResponse();
                if (imageHandlerRef.current) {
                    imageHandlerRef.current.clearInput();
                }
            }, 500);
        }
    };

    return (
        <div className="camera-content">
            {!isPhotoTaken ? (
                <video
                    className="camera-feed"
                    ref={videoRef}
                    autoPlay
                    playsInline
                />
            ) : (
                <div className="photo-preview">
                    <img
                        className="captured-photo"
                        src={imageUrl}
                        alt="Captured"
                    />
                    <div className="preview-buttons">
                        <button className="retake-button" onClick={retakePhoto}>
                            Retake Photo
                        </button>

                        <button
                            className="confirm-button"
                            onClick={confirmPhoto}
                        >
                            Confirm Photo
                        </button>
                    </div>
                </div>
            )}

            <canvas ref={canvasRef} style={{ display: "none" }} />

            <div className="camera-buttons">
                <div className="photo-buttons">
                    {!isPhotoTaken &&
                        (isMobileDevice() ? (
                            // should work, but not sure on actual mobile
                            <input
                                type="file"
                                accept="image/*"
                                capture="environment"
                                onChange={(event) => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            setImageUrl(reader.result);
                                            setIsPhotoTaken(true);
                                        };
                                        reader.readAsDataURL(file);
                                    }
                                }}
                                className="photo-button"
                            />
                        ) : (
                            // TODO: add photo button here as well
                            <button
                                className="photo-button"
                                onClick={takePhoto}
                            >
                                Take Photo
                            </button>
                        ))}
                </div>

                {typeof handleFileUpload !== "function" && (
                    <button
                        className="browse-button"
                        onClick={() =>
                            imageHandlerRef.current.handleIconClick()
                        }
                    >
                        Browse Photos
                    </button>
                )}

                <ImageHandler
                    ref={imageHandlerRef}
                    onImageUpload={handleImageUpload}
                />

                <button className="close-button" onClick={handleOnClose}>
                    Close
                </button>
            </div>
        </div>
    );
};
