/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Menu = ({ className, onClick }) => {
    return (
        <svg
            className={`menu ${className}`}
            onClick={onClick}
            fill="none"
            height="2.5rem"
            width="2.5rem"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="path"
                d="M5 19.5H35M5 9.75H35M5 29.25H35"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.5"
            />
        </svg>
    );
};
