import React, { useEffect, useRef, useState } from "react";

import { AskCollabria } from "../../../AskCollabria/AskCollabria";
import { Avatar } from "../../../Avatar/Avatar";
import { ChatBox } from "../../../ChatBox/ChatBox";
import { MenuBar } from "../../../MenuBar/MenuBar";

import Question from "../../../../icons/img/question-mark.png";
import Lia from "../../../../images/students/lia.webp";

import "./conversations.css";

// pass in a "student" prop to get information from each student
export const Conversations = ({ student }) => {
    const conversationRef = useRef(null);
    const [sliderValue, setSliderValue] = useState(0);

    const { renderChatAvatar } = Avatar();

    // mocked historical messages (dynamic or fetched in the future)
    const messages = [
        {
            text: "Hey, can you explain how a chemical reaction works?",
            isResponse: false,
            isSpecial: false,
        },
        {
            text: "Sure! A chemical reaction occurs when substances interact and form new substances.",
            isResponse: true,
            isSpecial: false,
        },
        {
            text: "Interesting. So, what happens to the original substances?",
            isResponse: false,
            isSpecial: true,
        },
        {
            text: "The original substances, called reactants, change during the reaction to form products, which are the new substances.",
            isResponse: true,
            isSpecial: false,
        },
        {
            text: "How do we know a reaction is happening?",
            isResponse: false,
            isSpecial: true,
        },
        {
            text: "Good question! Some signs include color changes, gas bubbles, temperature change, or even the formation of a solid.",
            isResponse: true,
            isSpecial: false,
        },
        {
            text: "What causes the reaction?",
            isResponse: false,
            isSpecial: false,
        },
        {
            text: "Reactions happen when reactant molecules collide with enough energy to break and form bonds. This energy is called activation energy.",
            isResponse: true,
            isSpecial: false,
        },
        {
            text: "Got it! Do all reactions need activation energy?",
            isResponse: false,
            isSpecial: true,
        },
        {
            text: "Yes, but the amount varies. Some reactions need a lot, while others need just a little.",
            isResponse: true,
            isSpecial: false,
        },
        {
            text: "Thanks! That clears it up!",
            isResponse: false,
            isSpecial: false,
        },
        {
            text: "You're welcome! Keep asking great questions!",
            isResponse: true,
            isSpecial: false,
        },
    ];

    // extract indices of special messages directly in render
    const specialIndices = messages
        .map((msg, index) => (msg.isSpecial ? index : null))
        .filter((index) => index !== null);

    // handle slider change to scroll conversation
    const handleSliderChange = (e) => {
        const scrollAmount = e.target.value;
        const conversation = conversationRef.current;
        const maxScroll = conversation.scrollHeight - conversation.clientHeight;

        // calculate and set the scroll position based on slider value
        conversation.scrollTop = (maxScroll * scrollAmount) / 100;
        setSliderValue(scrollAmount);
    };

    // handle conversation scroll to update slider
    const handleScroll = () => {
        const conversation = conversationRef.current;
        const maxScroll = conversation.scrollHeight - conversation.clientHeight;
        const scrollPercentage = (conversation.scrollTop / maxScroll) * 100;

        // update slider value based on scroll position
        setSliderValue(scrollPercentage);
    };

    // handle click within slider bar
    const handleSliderClick = (e) => {
        const slider = e.currentTarget;
        const clickY = e.clientY - slider.getBoundingClientRect().top;
        const sliderHeight = slider.clientHeight;
        const clickPercentage = clickY / sliderHeight;
        const newSliderValue = clickPercentage * 100;

        // set the new slider value and scroll the conversation
        setSliderValue(newSliderValue);

        const conversation = conversationRef.current;
        const maxScroll = conversation.scrollHeight - conversation.clientHeight;
        conversation.scrollTop = (maxScroll * newSliderValue) / 100;
    };

    useEffect(() => {
        const conversation = conversationRef.current;
        // attach scroll event listener
        conversation.addEventListener("scroll", handleScroll);

        return () => {
            // clean up event listener on component unmount
            conversation.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="frame">
            <div className="main-content">
                <MenuBar />

                <div className="conversations-content">
                    <div className="content-header">
                        <h1 className="conversations-name">Conversations</h1>
                        <AskCollabria />
                    </div>

                    <div className="student-content">
                        <div className="name-content">
                            <div className="profile-picture-content">
                                <img
                                    className="profile-picture"
                                    src={Lia}
                                    alt="Lia"
                                />
                            </div>
                            <h2 className="student-name">Lia</h2>
                        </div>

                        <div className="alert-content">
                            <img
                                className="alert"
                                src={Question}
                                alt="Question Alert"
                            />
                        </div>
                    </div>

                    <div className="conversations-wrapper">
                        {/* Top Marquee - Left to Right */}
                        <div className="marquee-top">
                            <p>
                                This is a PAST conversation from Monday, April
                                11 at 7:42pm. You're viewing historical
                                messages.
                            </p>
                        </div>

                        {/* Actual Conversation - Using ChatBox */}
                        <div className="conversation-wrapper">
                            <div className="conversation-content">
                                <ChatBox
                                    messages={messages}
                                    chatboxRef={conversationRef}
                                    avatarComponent={renderChatAvatar()}
                                    scrollAmount={sliderValue}
                                />
                            </div>

                            {/* Vertical Slider */}
                            <div className="slider" onClick={handleSliderClick}>
                                <input
                                    type="range"
                                    orient="vertical"
                                    min="0"
                                    max="100"
                                    value={sliderValue}
                                    onChange={handleSliderChange}
                                />
                                {specialIndices.map((index) => (
                                    <div
                                        key={index}
                                        className="special-marker"
                                        style={{
                                            top: `${
                                                (index / messages.length) * 100
                                            }%`,
                                        }}
                                    />
                                ))}
                            </div>
                        </div>

                        {/* Bottom Marquee - Right to Left */}
                        <div className="marquee-bottom">
                            <p>
                                This is a PAST conversation from Monday, April
                                11 at 7:42pm. You're viewing historical
                                messages.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
