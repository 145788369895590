import React from "react";

import { Star } from "../../svg/Star/Star";
import "./levelup.css";

export const LevelUp = ({ percentage, earnedStars, totalPoints }) => {
    return (
        <div className="levelup-content">
            <div className="levelup-score">
                <p className="levelup-percentage">{totalPoints} Points</p>

                <div className="stars">
                    {[...Array(10)].map((_, index) => (
                        <Star
                            key={index}
                            className={index < earnedStars ? "earned-star" : ""}
                        />
                    ))}
                </div>
            </div>

            <div className="levelup-wrapper">
                <div className="levelup-container">
                    <div
                        className="levelup-fill"
                        style={{ width: `${percentage}%` }}
                    />
                </div>{" "}
            </div>
        </div>
    );
};
