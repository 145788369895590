import React, { useState } from "react";

import { AskCollabria } from "../AskCollabria/AskCollabria";
import { MenuBar } from "../MenuBar/MenuBar";
import { Student } from "./Student/Student";

import "./students.css";

// hardcoded for now maybe store in a ".csv" file?
const studentList = [
    {
        id: 1,
        name: "Yeji",
        profilePicture: "yeji.webp",
        engagementStatus: "Very High",
        lastActive: "2023-04-11T19:42:00",
        hasAlert: "none",
        profileLink: "/profile/yeji",
    },
    {
        id: 2,
        name: "Lia",
        profilePicture: "lia.webp",
        engagementStatus: "Medium",
        lastActive: "2023-04-10T16:30:00",
        hasAlert: "question",
        profileLink: "/profile/lia",
    },
    {
        id: 3,
        name: "Ryujin",
        profilePicture: "ryujin.webp",
        engagementStatus: "Low",
        lastActive: "2023-04-09T08:15:00",
        hasAlert: "danger",
        profileLink: "/profile/ryujin",
    },
    {
        id: 4,
        name: "Chaeryeong",
        profilePicture: "chaeryeong.webp",
        engagementStatus: "High",
        lastActive: "2023-04-11T12:25:00",
        hasAlert: "none",
        profileLink: "/profile/chaeryeong",
    },
    {
        id: 5,
        name: "Yuna",
        profilePicture: "yuna.webp",
        engagementStatus: "Very Low",
        lastActive: "2023-04-11T14:10:00",
        hasAlert: "none",
        profileLink: "/profile/yuna",
    },
    {
        id: 6,
        name: "Yeji",
        profilePicture: "yeji.webp",
        engagementStatus: "Very High",
        lastActive: "2023-04-11T19:42:00",
        hasAlert: "none",
        profileLink: "/profile/yeji",
    },
    {
        id: 7,
        name: "Lia",
        profilePicture: "lia.webp",
        engagementStatus: "Medium",
        lastActive: "2023-04-10T16:30:00",
        hasAlert: "question",
        profileLink: "/profile/lia",
    },
    {
        id: 8,
        name: "Ryujin",
        profilePicture: "ryujin.webp",
        engagementStatus: "Low",
        lastActive: "2023-04-09T08:15:00",
        hasAlert: "danger",
        profileLink: "/profile/ryujin",
    },
    {
        id: 9,
        name: "Chaeryeong",
        profilePicture: "chaeryeong.webp",
        engagementStatus: "High",
        lastActive: "2023-04-11T12:25:00",
        hasAlert: "none",
        profileLink: "/profile/chaeryeong",
    },
    {
        id: 10,
        name: "Yuna",
        profilePicture: "yuna.webp",
        engagementStatus: "Very Low",
        lastActive: "2023-04-11T14:10:00",
        hasAlert: "none",
        profileLink: "/profile/yuna",
    },
];

export const Students = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [students] = useState(studentList);

    // filter students based on search query
    const filteredStudents = students.filter((student) =>
        student.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="frame">
            <div className="main-content">
                <MenuBar />

                <div className="students-content">
                    <div className="content-header">
                        <h1 className="students-name">Students</h1>
                        <AskCollabria />
                    </div>

                    {/* Search Bar */}
                    <input
                        className="search-bar"
                        type="text"
                        placeholder="Search Students..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    {/* List of Students */}
                    <ul className="students-list">
                        {filteredStudents.map((student) => (
                            <Student key={student.id} student={student} />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
