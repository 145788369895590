import React from "react";
import { useNavigate } from "react-router-dom";

import conversationsIcon from "../../icons/img/conversations.png";
import classroomIcon from "../../icons/img/classroom.svg";
import collabriaIcon from "../../icons/img/collabria.png";
import studentsIcon from "../../icons/img/students.png";

import { Assignment } from "../../icons/svg/Assignment/Assignment";
import { Menu } from "../../icons/svg/Menu/Menu";
import "./menubar.css";

export const MenuBar = () => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/");
    };

    const goToProfile = () => {
        navigate("/profile");
    };

    const goToClassroom = () => {
        navigate("/classroom");
    };

    const goToStudents = () => {
        navigate("/students");
    };

    const goToUploadAssignments = () => {
        navigate("/upload-assignments");
    };

    const goToConversations = () => {
        navigate("/conversations");
    };

    return (
        <div className="menu-content">
            <div className="upper-content">
                <img
                    className="icon collabria"
                    alt="Collabria Icon"
                    src={collabriaIcon}
                    onClick={goToHome}
                />
            </div>

            {/*<div className="lower-content">
                <Assignment
                    className="icon assignment"
                    alt="Assignment Icon"
                    onClick={goToUploadAssignments}
                />
            </div>*/}
        </div>
    );
};
