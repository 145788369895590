import { useEffect, useState } from "react";
import DefaultAvatar from "../../icons/img/student2.png";
import { AvatarHandler } from "../../utils/AvatarHandler";
import "./avatar.css";

export const Avatar = () => {
    const { loadAvatar, resetAvatar, defaultAvatar } = AvatarHandler();

    // initialize default avatar
    const [avatar, setAvatar] = useState(() => defaultAvatar());

    // load avatar when component mounts
    useEffect(() => {
        loadAvatar(setAvatar); // load avatar into state
    }, []);

    // function to render the icon avatar
    const renderIconAvatar = () => (
        <div className="avatar-icon">
            <img
                className="body"
                src={avatar.body || DefaultAvatar}
                alt="Avatar Body"
            />
            {avatar.head && (
                <img
                    className="head"
                    src={avatar.head || null}
                    alt="Avatar Hat"
                />
            )}
        </div>
    );

    // function to render the chat avatar
    const renderChatAvatar = () => (
        <div className="avatar-chat">
            <img
                className="body"
                src={avatar.body || DefaultAvatar}
                alt="Avatar Body"
            />
            {avatar.head && (
                <img
                    className="head"
                    src={avatar.head || null}
                    alt="Avatar Hat"
                />
            )}
        </div>
    );

    // return both the avatar state and the default render function
    return {
        avatar,
        setAvatar,
        resetAvatar,
        renderIconAvatar,
        renderChatAvatar,
    };
};
