const SERVER_URL = "https://backend.collabria.co"
const PORT = 5000

export const createAccount = async (fullName, email, password) => {

  const toUpload = {
    'name': fullName,
    'email': email,
    'password': password
  }
  
  return fetch(SERVER_URL + ":" + PORT + "/v1/auth/register", 
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(toUpload), // body data type must match "Content-Type" header
    }
  )
      .then(async res => {
        if (!res.ok) {
          const errorJson = await res.json()
          throw new Error(errorJson.message)
        }
        else
          return res.json()
      })
};

export const signIn = async (email, password) => {

  const toUpload = {
    'email': email,
    'password': password
  }
  return fetch(SERVER_URL + ":" + PORT + "/v1/auth/login", 
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(toUpload), // body data type must match "Content-Type" header
    }
  )
      .then(async res => {
        if (!res.ok) {
          const errorJson = await res.json()
          throw new Error(errorJson.message)
        }
        else
          return res.json()
      })
};

export const forgotPassword = async (email) => {

  const toUpload = {
    'email': email,
  }
  
  return fetch(SERVER_URL + ":" + PORT + "/v1/auth/forgot-password", 
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(toUpload), // body data type must match "Content-Type" header
    }
  )
      .then(res => res.json())
};

export const userVerification = async (token) => {
  const toUpload = {
    'token': token,
  }
  
  return fetch(SERVER_URL + ":" + PORT + "/v1/auth/verify-email", 
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(toUpload), // body data type must match "Content-Type" header
    }
  )
}

export const refreshAuthentication = async (token) => {
  const toUpload = {
    'refreshToken': token,
  }
  
  return fetch(SERVER_URL + ":" + PORT + "/v1/auth/refresh-tokens", 
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(toUpload), // body data type must match "Content-Type" header
    }
  )
      .then(res => res.json())
}