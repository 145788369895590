import { MathJax, MathJaxContext } from "better-react-mathjax";
import React from "react";
import teacherIcon from "../../../icons/img/teacher.png";
import { Loader } from "../../../icons/svg/Loader/Loader";
import "./chatmessage.css";

// Function to render highlighted text with specific colors
const renderHighlights = (text, allHighlightIndices, colors) => {
    let startingIndex = 0;
    let updatedResponse = [];

    allHighlightIndices.forEach((highlightIndices, i) => {
        // Push unhighlighted text before the highlight
        if (startingIndex < highlightIndices[0]) {
            updatedResponse.push(
                <span key={startingIndex}>
                    {text.substring(startingIndex, highlightIndices[0])}
                </span>
            );
        }

        // Push highlighted text
        updatedResponse.push(
            <span
                key={highlightIndices[0]}
                style={{ color: colors[i], fontWeight: "bold" }}
            >
                {text.substring(highlightIndices[0], highlightIndices[1])}
            </span>
        );

        startingIndex = highlightIndices[1]; // Update the starting index
    });

    // Push any remaining text after the last highlight
    if (startingIndex < text.length) {
        updatedResponse.push(
            <span key={startingIndex}>
                {text.substring(startingIndex, text.length)}
            </span>
        );
    }

    return updatedResponse; // Return the complete highlighted response
};

// Function to preprocess LaTeX text
const preprocessLatex = (text) => {
    return text
        .replace(/°/g, "^\\circ") // Convert degree symbol to LaTeX format
        .replace(/%%/g, "\\%%") // Escape custom inline delimiter
        .replace(/%%%(.*?)%%%/g, (match, p1) => `\\(${p1}\\)`) // Handle custom inline LaTeX
        .replace(/\$([^$]+)\$/g, "\\($1\\)") // Handle $ delimiters
        .replace(/\\\(/g, "\\(")
        .replace(/\\\)/g, "\\)")
        .replace(/\\\[/g, "\\[")
        .replace(/\\\]/g, "\\]");
};

// Main component for rendering chat messages with MathJax support
export const ChatMessage = ({ msg, avatarComponent }) => {
    // Determine if highlights should be displayed
    const displayHighlights =
        msg.isResponse && msg.highlightIndices && msg.highlightColors;

    // Configuration for MathJax rendering
    const config = {
        tex: {
            inlineMath: [
                ["%%", "%%"], // Custom inline delimiter
                ["$", "$"], // Standard inline delimiter
                ["\\(", "\\)"], // LaTeX inline delimiter
            ],
            displayMath: [
                ["%%%", "%%%"], // Custom display delimiter
                ["$$", "$$"], // Standard display delimiter
                ["\\[", "\\]"], // LaTeX display delimiter
            ],
        },
        loader: { load: ["input/tex", "output/chtml"] }, // Load necessary MathJax modules
    };

    return (
        <MathJaxContext config={config}>
            {msg.isLoading ? (
                // Show loader while response is being processed
                <div className="loader-container">
                    <Loader />
                </div>
            ) : (
                <div
                    className={`chat-message-container ${
                        msg.isResponse ? "response" : "user"
                    } ${msg.isSpecial ? "is-special" : ""}`}
                >
                    {msg.isImage ? (
                        <div className="chat-message">
                            <img
                                src={msg.text}
                                alt="Uploaded"
                                className="uploaded-image"
                            />
                        </div>
                    ) : (
                        <div
                            className={`chat-message ${
                                msg.isResponse ? "response" : "user"
                            }`}
                        >
                            {displayHighlights ? (
                                renderHighlights(
                                    msg.text,
                                    msg.highlightIndices,
                                    msg.highlightColors
                                )
                            ) : (
                                <MathJax dynamic={true}>
                                    {preprocessLatex(msg.text)}
                                </MathJax>
                            )}
                        </div>
                    )}

                    {!msg.isResponse && avatarComponent}

                    {!msg.isLoading && msg.isResponse && (
                        <img
                            className="teacher-icon-in-chat"
                            alt="Teacher Icon"
                            src={teacherIcon}
                        />
                    )}
                </div>
            )}
        </MathJaxContext>
    );
};
