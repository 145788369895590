import React, { useRef, useState } from "react";

import { Stars } from "../../icons/svg/Stars/Stars";

import "./userinput.css";

export const UserInput = ({
    setShowPopup,
    setCurrentPopup,
    avatarComponent,
    earnedPoints,
    handleSend,
    isGenerating,
}) => {
    const textAreaRef = useRef(null);
    const [inputValid, setInputValid] = useState(false);

    const handleInputChange = () => {
        if (textAreaRef.current) {
            const message = textAreaRef.current.value;
            setInputValid(message.trim().length > 0);
        }
    };

    const handleSendAndClear = () => {
        if (textAreaRef.current) {
            const message = textAreaRef.current.value;

            if (message.trim() && !isGenerating) {
                handleSend(message);
                textAreaRef.current.value = "";
                setInputValid(false);
            }
        }
    };

    const handleCameraPopup = () => {
        setShowPopup((prev) => !prev);
        setCurrentPopup("camera");
    };

    return (
        <div className="user-input-content">
            <div className="ask-content">
                <div className="ask-controls">
                    {/* <Microphone className="mic-icon" />
                    <Photo className="photo-icon" onClick={handleCameraPopup} /> */}

                    <textarea
                        ref={textAreaRef}
                        className="ask-text"
                        placeholder="Ask a question!"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                if (inputValid && !isGenerating)
                                    handleSendAndClear();
                            }
                        }}
                        onChange={handleInputChange}
                    />
                </div>

                <button
                    className={`send-button ${
                        inputValid && !isGenerating ? "send-button--active" : ""
                    }`}
                    onClick={handleSendAndClear}
                    disabled={!inputValid || isGenerating}
                >
                    Send
                </button>
            </div>

            <div className="icon-content">
                {earnedPoints > 0 && (
                    <div className="points-notification">
                        <Stars className="star-icon" />
                        <span className="points-text">+{earnedPoints}</span>
                    </div>
                )}
                {avatarComponent}
            </div>
        </div>
    );
};
