import React, { useEffect } from "react";

import DefaultAvatar from "../../../icons/img/student-default-female.png";
import { AvatarHandler } from "../../../utils/AvatarHandler";

import "../../Avatar/avatar.css";

export const AvatarPopUp = ({ handleOnBack, avatarState, setAvatarState }) => {
    const { applyHat } = AvatarHandler();

    useEffect(() => {
        applyHat(setAvatarState, avatarState);
    }, []);

    return (
        <>
            <div
                className={`avatar-popup ${avatarState.head ? "has-hat" : ""}`}
            >
                <img
                    className="body"
                    src={avatarState.body || DefaultAvatar}
                    alt="Avatar Body"
                />
                {avatarState.head && (
                    <img
                        className="head"
                        src={avatarState.head || null}
                        alt="Avatar Hat"
                    />
                )}
            </div>

            <button className="close-button" onClick={handleOnBack}>
                Back
            </button>
        </>
    );
};
