const BACKEND_URL = "https://backend.collabria.co:5000"
const API_PREFIX = "/v1/conversation"


export const sendChat = async (content, history, context, userId) => {
    var toUpload = {'input': content, 'history': history, 'context': context, 'userId': userId}
    console.dir(toUpload)
    
    return fetch(BACKEND_URL + API_PREFIX + "/chat", 
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(toUpload), // body data type must match "Content-Type" header  
      }
    )
        .then(async res => await res.text())
  };

  export const getSession = async (userId) => {
    var toUpload = {'userId': userId}
    
    return fetch(BACKEND_URL + "/v1/session", 
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(toUpload), // body data type must match "Content-Type" header  
      }
    )
        .then(async res => await res.json())
  };


  //Going to return a list of questions as well as a text, LLM response
export const sendOCR = async (file, devFlag=false) => {
    const formData = new FormData();
  
    formData.set('file', file);
    if (devFlag)
      formData.set('devFlag', true);
    
    return fetch(BACKEND_URL + API_PREFIX + "/ocr", 
      {
        method: "POST",

        mode: "cors",
        credentials: "same-origin",
        referrerPolicy: "no-referrer", 
        body: formData,
      }
    )
        .then(async res => await res.json())
  };

  