import React from "react";
import "./progressbar.css";

export const ProgressBar = ({ percentage }) => {
    return (
        <div className="progressbar-wrapper">
            <div className="progressbar-container">
                <div
                    className="progressbar-fill"
                    style={{ width: `${percentage}%` }} // set width dynamically based on percentage
                />
            </div>
            <p className="progressbar-percentage"></p>
        </div>
    );
};
