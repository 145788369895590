import React from "react";

import "./questionprompts.css";

export const QuestionPrompts = ({ sendMessage, generateResponse }) => {
    const questions = [
        "What is a chemical reaction?",
        "How does a chemical reaction work?",
        "What are common examples?",
    ]; // hardcoded for now, but later have as a prop

    return (
        <div className="question-prompts">
            {questions.map((question, index) => (
                <button
                    key={index} // unique key for each button
                    className={`question-prompt prompt-${index + 1}`}
                    onClick={() => {
                        sendMessage(question, true); // send the clicked question as a message
                        generateResponse(question);
                    }}
                >
                    <p>{question}</p>
                </button>
            ))}
        </div>
    );
};
