import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Classroom } from "./components/Classroom/Classroom";
import { MainScreen } from "./components/MainScreen/MainScreen";
import { Profile } from "./components/Profile/Profile";
import { Conversations } from "./components/Students/Student/Conversations/Conversations";
import { Students } from "./components/Students/Students";
import { UploadAssignments } from "./components/UploadAssignments/UploadAssignments";

const router = createBrowserRouter([
    {
        path: "/*",
        element: <MainScreen />,
    },
    {
        path: "/profile",
        element: <Profile />,
    },
    {
        path: "/classroom",
        element: <Classroom />,
    },
    {
        path: "/students",
        element: <Students />,
    },
    {
        path: "/upload-assignments",
        element: <UploadAssignments />,
    },
    {
        path: "/conversations",
        element: <Conversations />,
    },
]);

export const App = () => {
    return <RouterProvider router={router} />;
};
