import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import ImgDoc from "../../icons/img/documents/img-doc.gif";
import PDFDoc from "../../icons/img/documents/pdf-doc.png";

import { ProgressBar } from "../../icons/svg/ProgressBar/ProgressBar";
import { AssignmentsHandler } from "../../utils/AssignmentsHandler";
import { AskCollabria } from "../AskCollabria/AskCollabria";
import { MenuBar } from "../MenuBar/MenuBar";
import { PopUps } from "../PopUps/PopUps";

import "./uploadassignments.css";

export const UploadAssignments = () => {
    const [showPopup, setShowPopup] = useState(false);

    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [assignmentText, setAssignmentText] = useState("");
    const [uploadComplete, setUploadComplete] = useState(false);
    const fileInputRef = useRef(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedTitle, setEditedTitle] = useState("");
    const [showDragArea, setShowDragArea] = useState(true);
    const [numPages, setNumPages] = useState(null);

    const {
        assignments,
        fileProgress,
        updateAssignmentTitle,
        handleFileUpload,
    } = AssignmentsHandler(selectedAssignment, setAssignmentText);

    const togglePopup = () => {
        setShowPopup((prev) => !prev);
    };

    useEffect(() => {
        if (assignments.length > 0 && !selectedAssignment) {
            setSelectedAssignment(assignments[0]);
            setAssignmentText(assignments[0].text);
            setShowDragArea(false);
        }
    }, [assignments, selectedAssignment]);

    useEffect(() => {
        if (assignments.length > 0) {
            const lastAssignment = assignments[assignments.length - 1];
            if (fileProgress[lastAssignment.title] === 100) {
                setUploadComplete(true);
            }
        }
    }, [assignments, fileProgress]);

    const overallProgress = () => {
        const totalAssignments = assignments.length;
        if (totalAssignments === 0) return 0;

        const totalProgress = Object.values(fileProgress).reduce(
            (acc, progress) => acc + progress,
            0
        );
        return Math.round((totalProgress / (totalAssignments * 100)) * 100);
    };

    const overallLoading = Object.values(fileProgress).some(
        (progress) => progress < 100
    );

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        if (files.length > 0) {
            handleFileUpload({ target: { files } });
            setShowDragArea(false);
        }
    };

    const handleBrowseClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDocumentClick = (assignment) => {
        setSelectedAssignment(assignment);
        setAssignmentText(assignment.text);
    };

    const handleTitleClick = (index, title) => {
        setEditingIndex(index);
        setEditedTitle(title);
    };

    const handleTitleChange = (e) => {
        setEditedTitle(e.target.value);
    };

    const handleTitleBlur = (index, assignment) => {
        setEditingIndex(null);
        if (editedTitle !== assignment.title) {
            const currentProgress = fileProgress[assignment.title]; // Store current progress
            updateAssignmentTitle(index, editedTitle, currentProgress); // Pass progress to the update function
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const previewUrl =
        selectedAssignment && selectedAssignment.file
            ? URL.createObjectURL(selectedAssignment.file)
            : null;

    return (
        <div className="frame">
            <div className="main-content">
                <MenuBar />
                <div className="upload-assignments-content">
                    <div className="content-header">
                        <h1 className="upload-assignments-name">
                            Upload Assignments
                        </h1>
                    </div>

                    <div className="upload-container">
                        {showDragArea && !selectedAssignment && (
                            <div
                                className="file-upload-area"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                            >
                                <input
                                    type="file"
                                    accept="application/pdf,image/*"
                                    onChange={(e) => {
                                        handleFileUpload(e);
                                        setShowDragArea(false);
                                    }}
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    multiple
                                />
                                <div className="drag-drop-area">
                                    <p className="drag-drop">
                                        Drag & Drop Files Here
                                    </p>

                                    <p className="drag-drop">Or</p>

                                    <div className="drag-drog-buttons">
                                        <button
                                            className="drag-drop-button"
                                            onClick={handleBrowseClick}
                                        >
                                            Browse Current Files
                                        </button>
                                        <button
                                            className="drag-drop-button"
                                            onClick={togglePopup}
                                        >
                                            Take Photo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showPopup && (
                            <PopUps
                                currentPopup={"camera"}
                                setShowPopup={setShowPopup}
                                handleFileUpload={handleFileUpload}
                            />
                        )}

                        {!showDragArea && (
                            <>
                                <div className="uploaded-docs">
                                    <>
                                        <h3 className="documents">
                                            Uploaded Documents
                                        </h3>
                                        <ul className="documents-list">
                                            {assignments.map(
                                                (assignment, index) => (
                                                    <li
                                                        key={index}
                                                        className="document-item"
                                                        onClick={() =>
                                                            handleDocumentClick(
                                                                assignment
                                                            )
                                                        }
                                                    >
                                                        <div className="document-icon">
                                                            {assignment.fileType ===
                                                            "application/pdf" ? (
                                                                <img
                                                                    src={PDFDoc}
                                                                    alt="PDF Icon"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ImgDoc}
                                                                    alt="Image Icon"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="document-info">
                                                            <div className="document-details">
                                                                {editingIndex ===
                                                                index ? (
                                                                    <input
                                                                        type="text"
                                                                        value={
                                                                            editedTitle
                                                                        }
                                                                        onChange={
                                                                            handleTitleChange
                                                                        }
                                                                        onBlur={() =>
                                                                            handleTitleBlur(
                                                                                index,
                                                                                assignment
                                                                            )
                                                                        }
                                                                        onKeyDown={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                e.key ===
                                                                                "Enter"
                                                                            ) {
                                                                                handleTitleBlur(
                                                                                    index,
                                                                                    assignment
                                                                                );
                                                                            }
                                                                        }}
                                                                        autoFocus
                                                                    />
                                                                ) : (
                                                                    <p
                                                                        onClick={() =>
                                                                            handleTitleClick(
                                                                                index,
                                                                                assignment.title
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            assignment.title
                                                                        }
                                                                    </p>
                                                                )}
                                                                <small className="date">
                                                                    {assignment.date.toLocaleString()}
                                                                </small>
                                                            </div>
                                                            <ProgressBar
                                                                className="progress-bar"
                                                                percentage={
                                                                    editingIndex ===
                                                                    index
                                                                        ? fileProgress[
                                                                              assignment
                                                                                  .title
                                                                          ] || 0
                                                                        : fileProgress[
                                                                              assignment
                                                                                  .title
                                                                          ] || 0
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </>

                                    <div
                                        className="drag-drop-list-item"
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                    >
                                        <input
                                            type="file"
                                            accept="application/pdf,image/*"
                                            onChange={(e) => {
                                                handleFileUpload(e);
                                                setShowDragArea(false);
                                            }}
                                            ref={fileInputRef}
                                            style={{ display: "none" }}
                                            multiple
                                        />

                                        <div className="drag-drog-buttons">
                                            <button
                                                className="drag-drop-button"
                                                onClick={handleBrowseClick}
                                            >
                                                Add More Files
                                            </button>
                                            <button
                                                className="drag-drop-button"
                                                onClick={togglePopup}
                                            >
                                                Take More Photos
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="document-content">
                                    {overallLoading && (
                                        <div className="documents-loading">
                                            <ProgressBar
                                                percentage={overallProgress()}
                                            />
                                        </div>
                                    )}

                                    {/* Preview section for PDFs and images */}
                                    {selectedAssignment && uploadComplete && (
                                        <div className="document-preview">
                                            {selectedAssignment.fileType ===
                                            "application/pdf" ? (
                                                <Document
                                                    file={previewUrl}
                                                    onLoadSuccess={
                                                        onDocumentLoadSuccess
                                                    }
                                                >
                                                    {Array.from(
                                                        new Array(numPages),
                                                        (el, index) => (
                                                            <Page
                                                                key={`page_${
                                                                    index + 1
                                                                }`}
                                                                pageNumber={
                                                                    index + 1
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Document>
                                            ) : (
                                                <img
                                                    src={previewUrl}
                                                    alt="Uploaded Preview"
                                                    className="uploaded-preview"
                                                />
                                            )}
                                        </div>
                                    )}

                                    {/* Text editor for assignment text */}
                                    {selectedAssignment && (
                                        <textarea
                                            value={assignmentText}
                                            onChange={(e) =>
                                                setAssignmentText(
                                                    e.target.value
                                                )
                                            }
                                            rows={10}
                                            cols={50}
                                            placeholder="Assignment questions will appear here..."
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
